import React, { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";

import useAuth from './hooks/useAuth';
import { AppRoutes, PrivateRoutes } from './routes/routes';

// *import components
import Login from './view/Auth/Login';

const theme = createTheme({
  palette: {
    primary: {
      main: '#40508c',
      contrastText: '#fff',
    },
    secondary: {
      main: '#1d1c55',
      contrastText: '#fff',
    },
  },

});



function App() {

  const { user, verifyToken } = useAuth();

  const token = localStorage.getItem('jwt')

  useEffect(() => {
    AOS.init({
      duration: 600,
      easing: 'ease-in-sine',
    });

    verifyToken()
  }, []);



  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>

        {/* ========== App Routes ========== */}
        <Routes>
          {AppRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={route.component}
            />
          ))}
          <Route element={!user ? <Outlet /> : <Navigate to="/" />}>
            <Route path="/auth/sign-in" element={<Login />} />
          </Route>
          <Route element={user || token ? <Outlet /> : <Navigate to="/auth/sign-in" />}>
            {PrivateRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>
        </Routes>

      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
