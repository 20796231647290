import React from 'react';
import { Box, Typography, Grid, Container } from '@mui/material';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import "swiper/css";
import "swiper/css/pagination";

//* Impoert Components
import Images from 'app/assets/Images';
import colors from 'app/styles/colors';

function Testimonial() {

  SwiperCore.use([Autoplay, Pagination]);

  return (
    <Box sx={{ py: 10, my: 6, textAlign: 'center', bgcolor: '#F9FAFB' }}>
      <Container>
        <Typography variant="body1" sx={{ color: colors.textPrimary }} >TESTIMONIALS</Typography>
        <Typography variant="h4" className="business" sx={{
          mt: 1.5, mb: 3.5, fontWeight: 600,
        }} >Who Love Our Work</Typography>
        <Box sx={{ mt: 6, mb: 4 }}>
          <Swiper
            className="mySwiper"
            loop={true}
            slidesPerView={1}
            spaceBetween={5}
            speed={3000}
            pagination={{ "dynamicBullets": true, "clickable": true, }}
            autoplay={{
              "delay": 3000,
              "disableOnInteraction": false,
            }}
          >
            <SwiperSlide>
              <Grid container spacing={2} justifyContent="center" sx={{ my: 2 }}>
                <Grid item md={8} container spacing={1} alignItems="center" justifyContent="space-between" sx={{ textAlign: 'left' }} >
                  <Grid item md={9}>
                    <Typography variant="body1" sx={{ color: colors.textPrimary }} >
                      Amazing experience i love it a lot. Thanks to the team that dreams come true, great! I appreciate there attitude and approach.
                    </Typography>
                  </Grid>
                  <Grid item md={3} sx={{ textAlign: 'center' }}>
                    <Typography component="img" src={Images.testimonial1} alt="testimonial" sx={{ width: 90, height: 90, borderRadius: 50 }} />
                  </Grid>
                </Grid>
              </Grid>
            </SwiperSlide>
            <SwiperSlide>
              <Grid container spacing={2} justifyContent="center" sx={{ my: 2 }}>
                <Grid item md={8} container spacing={1} alignItems="center" justifyContent="space-between" sx={{ textAlign: 'left' }} >
                  <Grid item md={9}>
                    <Typography variant="body1" sx={{ color: colors.textPrimary }} >
                      Amazing experience i love it a lot. Thanks to the team that dreams come true, great! I appreciate there attitude and approach.
                    </Typography>
                  </Grid>
                  <Grid item md={3} sx={{ textAlign: 'center' }}>
                    <Typography component="img" src={Images.testimonial2} alt="testimonial" sx={{ width: 90, height: 90, borderRadius: 50 }} />
                  </Grid>
                </Grid>
              </Grid>
            </SwiperSlide>
            <SwiperSlide>
              <Grid container spacing={2} justifyContent="center" sx={{ my: 2 }}>
                <Grid item md={8} container spacing={1} alignItems="center" justifyContent="space-between" sx={{ textAlign: 'left' }} >
                  <Grid item md={9}>
                    <Typography variant="body1" sx={{ color: colors.textPrimary }} >
                      Amazing experience i love it a lot. Thanks to the team that dreams come true, great! I appreciate there attitude and approach.
                    </Typography>
                  </Grid>
                  <Grid item md={3} sx={{ textAlign: 'center' }}>
                    <Typography component="img" src={Images.testimonial3} alt="testimonial" sx={{ width: 90, height: 90, borderRadius: 50 }} />
                  </Grid>
                </Grid>
              </Grid>
            </SwiperSlide>
          </Swiper>
        </Box>
      </Container>
    </Box>
  );
}

export default Testimonial;