import { useState } from "react";
import { toast } from "react-toastify";

function useProvideAuth() {

  const [user, setUser] = useState(null);

  const signIn = (token) => {
    localStorage.setItem('jwt', token)
    setUser(token)
  }

  const signOut = () => {
    toast.success('Logout Successfully!', {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
    localStorage.clear()
    setUser(null)

  }

  const verifyToken = () => {
    const token = localStorage.getItem('jwt')
    setUser(token)
  }

  return {
    user,
    signIn,
    signOut,
    verifyToken
  };
}

export default useProvideAuth;