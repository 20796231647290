import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, FormControl, Grid, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';


import { emailRegex } from 'app/utils';
import Images from 'app/assets/Images';
import useAuth from 'app/hooks/useAuth';

//  *import components
import { Error } from 'app/components/UI/Error';
import colors from 'app/styles/colors';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  section: {
    backgroundImage: `url(${Images.worldBack})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: "bottom",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative"
  },
});

export default function Login() {

  const { signIn } = useAuth()

  const classes = useStyles();

  const { register, handleSubmit, formState: { errors }, } = useForm();


  const userLogin = (data) => {
    console.log('file: Login.js => line 30 => userLogin => data', data);
    signIn("123")
  }

  return (
    <section className={classes.section}>

      <Button variant='contained' component={Link} to="/" sx={{ position: "absolute", top: 20, left: 20 }}>Home</Button>
      <Box sx={{
        maxWidth: "400px",
        backdropFilter: "saturate(300%) blur(20px)",
        bgcolor: "rgba(255, 255, 255, 0.8)",
        borderRadius: "10px"
      }}>
        <Box sx={{ mt: 3, textAlign: "center" }}>
          <img src={Images.logo} alt="" width="120px" />
        </Box>
        <Box sx={{ mt: 2, px: 4 }}>
          <Typography variant="h4" fontWeight="bold" color="secondary" textAlign="center" gutterBottom={true}>Login</Typography>
          <Typography variant="body2" color="textSecondary" textAlign="center">Welcome Back</Typography>
          <Box sx={{ my: 3 }}>
            <Typography component="form" onSubmit={handleSubmit(userLogin)} >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="standard"  >
                    <TextField
                      variant="outlined"
                      type="email"
                      label="Email"
                      error={errors?.email?.message && (true)}
                      {...register("email", {
                        required: 'Email is required',
                        pattern: {
                          value: emailRegex,
                          message: 'Please enter a valid email address',
                        }
                      })}
                    />
                    {errors?.email?.message && (
                      <Error message={errors?.email?.message} />
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="standard" >
                    <TextField
                      variant="outlined"
                      type="password"
                      label="Password"
                      error={errors?.password?.message && (true)}
                      {...register("password", {
                        required: 'Password is required',
                      })}
                    />
                    {errors?.password?.message && (
                      <Error message={errors?.password?.message} />
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 3 }}>
                    <Typography variant="body2" color="initial">Forgot Password ?</Typography>
                    <Button type="submit" variant="contained" color="primary" size="large" className={classes.primaryAction}>
                      Login
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Typography>
          </Box>
        </Box>
      </Box>
    </section>
  );
}