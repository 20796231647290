import React from 'react'
import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material'

//* Imported Component
import Images, { LearningBackground } from 'app/assets/Images'
import CardCourse from 'app/components/CardCourse/CardCourse'
import Layout from 'app/Layout/Layout'
import colors from 'app/styles/colors'

function Learning() {
  return (
    <Layout>
      <Box sx={{ position: "relative", overflow: "hidden" }}>

        <Box sx={{ position: "absolute", width: "60%", top: -100, right: 0, zIndex: -1 }}>
          <LearningBackground />
        </Box>

        <Grid container sx={{ zIndex: 1, display: "flex", justifyContent: 'space-around' }}>
          <Grid item xs={12} md={6} sx={{ width: "100%" }}>
            <img src={Images.learningCharacter} alt="learning" />
          </Grid>
          <Grid item xs={12} md={4.5} sx={{ color: colors.white, textAlign: "center", my: 9 }}>
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>Learning</Typography>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>Great Entrepreneurs are Built Here?</Typography>
            <Typography variant="body1" sx={{ textAlign: 'center', width: "100%" }}>Some text here.Some text here.Some text here.Some text here.Some text here.
              Some text here.Some text here.Some text here.Some text here.Some text here.
            </Typography>
            <Button variant="outlined" sx={{
              color: colors.white, border: `2px solid ${colors.white}`, my: 3,
              ":hover": {
                backgroundColor: colors.white,
                border: `3px solid ${colors.primaryShade
                  }`,
                color: colors.primaryDark,
                fontWeight: "bold",
              }
            }}>Explore</Button>
          </Grid>
        </Grid>

      </Box>
      <Container>
        <Typography sx={{
          bgcolor: colors.primaryDark, color: colors.white, width: '100%',
          textAlign: "center", borderRadius: '8px', mt: 4, fontSize: "28px",
        }}>

          Great Entrepreneurs are Built Here?
        </Typography>
        <Grid container sx={{ display: 'flex', justifyContent: "center", p: 2, mb: 4 }}>
          <Grid item xs={12} md={7} sx={{ my: "auto" }}>
            <Typography sx={{ fontSize: "18px", color: colors.textSecondary, textAlign: "justify" }}>The ecommerce industry is growing at an impressive rate, with opportunities for entrepreneurs who can take advantage of this trend. These Training Programs offer passionate trainers that will guarantee success from their top-notch expertise in 6 different verticals.

              The Mashr is committed to giving everyone the chance and opportunity for success. We understand how challenging it might seem when you have limited funds, so apply now to our upcoming training sessions where training is tailored specifically towards those with smaller budgets who wish to start earning money from home as freelancers or virtual assistants through skill development.</Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <img style={{ width: "100%" }} src={Images.learningLowerSection} alt="Learning" />
          </Grid>
        </Grid>
        <Divider />
        <CardCourse />
      </Container>
    </Layout>
  )
}

export default Learning