
import { BsPersonCircle, BsCardList } from "react-icons/bs"
import { MdEditNote } from "react-icons/md"
import colors from "./styles/colors"


const style = {
  fontSize: 25,
  verticalAlign: 'bottom',
  mr: 1,
  color: colors.primaryLight
}

const sideNavItems = [
  { icon: <BsPersonCircle style={style} className="listItem" />, label: 'Profile', path: '/profile' },
  { icon: <BsCardList style={style} className="listItem" />, label: 'Orders', path: '/orders' },
  { icon: <MdEditNote style={style} className="listItem" />, label: 'Invoice', path: '/invoice' },
]

export default sideNavItems