import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import colors from 'app/styles/colors';

function ProgressBar(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate"  {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color={colors.white}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}



ProgressBar.propTypes = {

  value: PropTypes.number.isRequired,
};

export default function CircularStatic({ value }) {
  const [progress, setProgress] = useState(10);

  // useEffect(() => {
  //   setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
  // }, []);

  return <ProgressBar value={value} />;
}
