import React, { Fragment } from "react";
import Marquee from 'react-fast-marquee';
import { makeStyles } from '@mui/styles';
import Images from 'app/assets/Images';
import colors from 'app/styles/colors';
import { Box } from "@mui/material";


const useStyles = makeStyles({
  box_img: {
    margin: "10px 20px",
    borderRadius: "12px",
    width: "90px",
    height: "90px",
    backgroundColor: colors.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sliderImg: {
    width: "80px",
    borderRadius: "12px",
    height: "fit-content",
  },

})


function Slider() {

  const classes = useStyles();
  return (
    <Fragment>
      <Marquee direction="right" speed={20} delay={1}>
        <Box className={classes.box_img}>
          <img className={classes.sliderImg} src={Images.icon1} alt="" />
        </Box>
        <Box className={classes.box_img}>
          <img className={classes.sliderImg} src={Images.icon2} alt="" />
        </Box>
        <Box className={classes.box_img}>
          <img className={classes.sliderImg} src={Images.icon3} alt="" />
        </Box>
        <Box className={classes.box_img}>
          <img className={classes.sliderImg} src={Images.icon4} alt="" />
        </Box>
        <Box className={classes.box_img}>
          <img className={classes.sliderImg} src={Images.icon5} alt="" />
        </Box>
        <Box className={classes.box_img}>
          <img className={classes.sliderImg} src={Images.icon6} alt="" />
        </Box>
        <Box className={classes.box_img}>
          <img className={classes.sliderImg} src={Images.icon7} alt="" />
        </Box>
        <Box className={classes.box_img}>
          <img className={classes.sliderImg} src={Images.icon8} alt="" />
        </Box>
        <Box className={classes.box_img}>
          <img className={classes.sliderImg} src={Images.icon9} alt="" />
        </Box>
        <Box className={classes.box_img}>
          <img className={classes.sliderImg} src={Images.icon10} alt="" />
        </Box>
      </Marquee>
      <Marquee direction="left" speed={20} delay={1}>
        <Box className={classes.box_img}>
          <img className={classes.sliderImg} src={Images.icon1} alt="" />
        </Box>
        <Box className={classes.box_img}>
          <img className={classes.sliderImg} src={Images.icon2} alt="" />
        </Box>
        <Box className={classes.box_img}>
          <img className={classes.sliderImg} src={Images.icon3} alt="" />
        </Box>
        <Box className={classes.box_img}>
          <img className={classes.sliderImg} src={Images.icon4} alt="" />
        </Box>
        <Box className={classes.box_img}>
          <img className={classes.sliderImg} src={Images.icon5} alt="" />
        </Box>
        <Box className={classes.box_img}>
          <img className={classes.sliderImg} src={Images.icon6} alt="" />
        </Box>
        <Box className={classes.box_img}>
          <img className={classes.sliderImg} src={Images.icon7} alt="" />
        </Box>
        <Box className={classes.box_img}>
          <img className={classes.sliderImg} src={Images.icon8} alt="" />
        </Box>
        <Box className={classes.box_img}>
          <img className={classes.sliderImg} src={Images.icon9} alt="" />
        </Box>
        <Box className={classes.box_img}>
          <img className={classes.sliderImg} src={Images.icon10} alt="" />
        </Box>
      </Marquee>
    </Fragment>
  );
}

export default Slider;