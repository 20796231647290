import clsx from 'clsx'
import React, { Fragment } from 'react'
import { Grid, Box, Button, Typography, FormControl, TextField, Autocomplete } from '@mui/material'
import { makeStyles } from '@mui/styles'

//* Imported Components
import Images from 'app/assets/Images'
import CircularStatic from 'app/components/ProgressBar/ProgressBar'
import colors from 'app/styles/colors'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFormLabel-root": {
      color: colors.white
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `2.5px solid ${colors.primaryLight}`,
      ":hover": {
        border: `2.5px solid ${colors.primaryLight} !important`,
      }

    },
    "& .Mui-focused": {
      color: `${colors.white} !important`

    },
    "& .MuiOutlinedInput-input": {
      color: colors.white
    },
    // "& .css-1ftyaf0": {
    //   color: colors.white
    // },
    "& .MuiSvgIcon-root": {
      color: colors.white
    },
  }
}));


//* Entity Ending List
const entityEndingOptions = [
  { label: 'LLC' },
  { label: 'L.L.C.' },
  { label: 'Limited Liability Company' },
]


function Name() {

  //* For Input Type
  // const [selectedInputIndex, setselectedInputIndex] = useState();

  const classes = useStyles();
  return (
    <Fragment>
      <Box
        sx={{
          height: "100vh", width: '100%', backgroundImage: `linear-gradient(to right bottom, #1d1c55,#40508c, #232e57,#778ad1);`,
          display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center",
          p: 6, boxSizing: 'border-box',
        }}
      >
        <Box sx={{
          display: "flex", alignItems: "center", justifyContent: "center", mb: "25px",
          cursor: "pointer", textDecoration: "none"
        }}
          component={Link} to="/"
        >
          <img src={Images.logo2} alt="" width={60} />
          <Typography variant="h5" color={colors.white} fontWeight="bold">EasyFiles</Typography>
        </Box>
        <Box sx={{
          height: "475px", width: "55%", backgroundColor: "rgba(20,20,20,.32)", alignItems: "center",
          color: colors.white, p: 6, borderRadius: "12px",
        }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography >Company Details · Mangotech Limited Liability Company</Typography>
            <CircularStatic value={40} />
          </Box>
          <Box sx={{ textAlign: "center", my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>What do you want to name your company?</Typography>
            <Typography variant="body2">We'll instantly check if your company name is available with the State of Wyoming.</Typography>
          </Box>
          <Box sx={{ minHeight: "284px" }}>
            <Grid container sx={{ justifyContent: "space-between" }}>
              <Grid item xs={12} md={5.5}>
                <FormControl variant="standard" fullWidth sx={{ my: 1, }}>
                  <TextField
                    className={classes.root}
                    id="outlined-textarea"
                    variant="outlined"
                    label="Company Name"
                    InputProps={{
                      sx: {
                        color: "white"
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5.5}>
                <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                  <Autocomplete
                    className={classes.root}
                    disablePortal
                    id="combo-box-demo"
                    options={entityEndingOptions}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params}
                      variant="outlined"
                      label="Entity Ending"


                    />}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Box>
              <Typography variant="body1">Tips for choosing your the right name</Typography>
              <ul>
                <li>
                  <Box >
                    <Typography variant="body2">Avoid common words such as "computers".</Typography>
                    <Typography sx={{ fontSize: "12px", color: colors.textSecondary }}>Words like "bank" or "school" can be subject to additional State approval restricted words.</Typography>
                  </Box>
                </li>
                <li>
                  <Typography variant="body2">Try a name with two or more words.</Typography>
                </li>
                <li>
                  <Typography variant="body2">Don’t start your name with "A" or "An."</Typography>
                </li>
                <li>
                  <Typography variant="body2">Try using English words and avoid using Special Characters.</Typography>
                </li>
                <li>
                  <Typography variant="body2">Double-check your spelling.</Typography>
                </li>
                <li>
                  <Typography variant="body2">Consider case-sensitivity.</Typography>
                </li>
              </ul>
            </Box>
          </Box>
          <Box sx={{ my: 4 }}>
            <Button variant="outlined" sx={{ float: "left", color: colors.white, border: `1px solid ${colors.white}` }}
              component={Link} to="/start/company-state"
            >Back</Button>
            <Button variant="contained" sx={{ float: "right" }}
              component={Link} to="/start/company-info/industry"
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}

export default Name