import { Box, Button, CardMedia, Container, Divider, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import colors from 'app/styles/colors';
import Layout from 'app/Layout/Layout';
import Images from 'app/assets/Images';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  boxStyle: {
    border: `1px solid ${colors.gray}`,
    borderRadius: "10px",
    padding: "20px 10px",
    minHeight: "50px",
    cursor: "pointer",
    textAlign: "center",
    "&:hover": {
      border: `1px solid ${colors.primaryDark}`
    }
  },
  img: {
    borderRadius: "20px"
  },
  listItem: {
    color: colors.gray,
    fontSize: "14px"
  },
  boxes: {
    fontWeight: "bold",
    border: `1px solid ${colors.gray}`,
    padding: "24px 0px",
    borderRadius: "10px",
    textAlign: "center",
    transition: "all .3s ease-in",
    "&:hover": {
      backgroundColor: colors.primaryLight,
      color: colors.white
    }
  }
})
function Contact() {
  const classes = useStyles()
  return (
    <Layout>

      <Box
      // sx={{

      //   backgroundImage: " linear-gradient(  #524c74, #6b6683, #848192, #9694a0, #a9a8ae, #bcbcbc, #cccccc, #dddddd, #eeeeee, #ffffff)"
      // }} 
      >





        <CardMedia


          sx={{

            width: "100%",
            // opacity: "20%",


          }}

          image={Images.c}
          alt="green iguana"
        >

          <Box sx={{ bgcolor: 'rgba(0,0,0,0.5)' }}>
            <Typography variant='h2' fontWeight="bold"
              sx={{
                fontSize: { xl: 20, md: 46, xs: 38 },
                color: colors.white,
                textAlign: "center",
                py: 15,
                zIndex: 10

              }}
            >
              Contact Us!
            </Typography>
          </Box>





        </CardMedia>


        <Container>
          <Grid container p={2}
            sx={{
              display: "flex",
              justifyContent: "left",
              flexWrap: { xs: "wrap-reverse" }
            }}>
            <Grid item xs={12} md={4} >
              <CardMedia
                component="img"
                width="100%"
                image={Images.call}
                alt="green iguana"
              />
            </Grid>
            <Grid item xs={12} md={3} >
              <Typography variant='h4' fontWeight="bold"
                sx={{

                  color: colors.primaryDark,
                  ":hover": {
                    color: colors.primaryLight,

                  },
                  py: 4,
                  textAlign: 'left',

                  fontWeight: 550,

                }}>
                Give Us A Call
                <Divider fontWeight='bold' sx={{
                  background: "black",

                }} />
              </Typography>
              <Typography
                component="div" fontWeight="bold"
                sx={{
                  fontSize: "15px",

                  color: colors.primaryDark,
                  ":hover": {
                    color: colors.primaryLight,

                  },
                  textAlign: 'left',
                  fontWeight: 400,

                }} >
                When it comes to joining an e-Commerce business,
                timing with the right approach is crucial. So, we have set
                the stage for our customers by providing the best services
                with the winning strategies. the best services
                with the winning strategies. the best services
                with the winning strategies.
              </Typography>

            </Grid>
          </Grid>

          <Grid container p={2} sx={{ display: "flex", justifyContent: "right" }}>

            <Grid item xs={12} md={3}>
              <Typography variant='h4' fontWeight="bold"
                sx={{

                  color: colors.primaryDark,
                  ":hover": {
                    color: colors.primaryLight,

                  },
                  py: 4,
                  textAlign: 'left',

                  fontWeight: 550,

                }}>
                Chat With Us
                <Divider fontWeight='bold' sx={{
                  background: "black",

                }} />
              </Typography>
              <Typography
                component="div" fontWeight="bold"
                sx={{
                  fontSize: "15px",

                  color: colors.primaryDark,
                  ":hover": {
                    color: colors.primaryLight,

                  },
                  textAlign: 'left',
                  fontWeight: 400,

                }} >
                When it comes to joining an e-Commerce business,
                timing with the right approach is crucial. So, we have set
                the stage for our customers by providing the best services
                with the winning strategies.So, we have set
                the stage for our customers by providing the best services
                with the winning strategies.

              </Typography>

            </Grid>
            <Grid item xs={12} md={4}>
              <CardMedia
                component="img"

                width="100%"
                image={Images.chat}
                alt="green iguana"
              />
            </Grid>
          </Grid>6

          <Grid container p={2} sx={{ display: "flex", justifyContent: "left", flexWrap: { xs: "wrap-reverse" } }}>
            <Grid item xs={12} md={4}>
              <CardMedia
                component="img"
                width="100%"
                image={Images.email}
                alt="green iguana"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant='h4' fontWeight="bold"
                sx={{

                  color: colors.primaryDark,
                  ":hover": {
                    color: colors.primaryLight,

                  },
                  py: 4,
                  textAlign: 'left',

                  fontWeight: 550,

                }}>
                Email Us:
                <Divider fontWeight='bold' sx={{
                  background: "black",

                }} />
              </Typography>
              <Typography
                component="div" fontWeight="bold"
                sx={{
                  fontSize: "15px",

                  color: colors.primaryDark,
                  ":hover": {
                    color: colors.primaryLight,

                  },
                  textAlign: 'left',
                  fontWeight: 400,

                }} >
                When it comes to joining an e-Commerce business,
                timing with the right approach is crucial. So, we have set
                the stage for our customers by providing the best services
                with the winning strategies.So, we have set
                the stage for our customers by providing the best services
                with the winning strategies.
              </Typography>

            </Grid>
          </Grid>
        </Container>

        <Typography variant='h4' fontWeight="bold"
          sx={{
            color: colors.primaryDark,
            py: 4,
            textAlign: 'center',
            fontWeight: 550,

          }}>
          Let's talk about everything!
        </Typography>
        <Typography
          component="div" fontWeight="bold"
          sx={{
            fontSize: "15px",

            color: colors.primaryDark,

            textAlign: 'center',
            fontWeight: 400,

          }} >
          Our customer support team is just amazing to get some assistance from.

          Give it a try!
        </Typography>

        <Box sx={{ backgroundColor: "#DCDFE0", mt: 2 }}>
          <Container>
            <Grid container py={5} alignItems="center" sx={{ flexWrap: { xs: "wrap-reverse" } }}>
              <Grid item xs={12} md={6}>
                <CardMedia
                  component="img"
                  width="100%"
                  image={Images.combine}
                  alt="green iguana"
                />
              </Grid>
              <Grid container item xs={12} md={6} spacing={3} >
                <Grid item xs={12}>
                  <TextField id="outlined-basic" label="Name" variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <TextField id="outlined-basic" label="Email" variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <TextField id="outlined-basic" label="Subject" variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <TextField id="outlined-basic" label="Message" variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" fullWidth sx={{ borderRadius: 2, p: 2, bgcolor: "rgba(29,28,85,0.5)" }}>Send</Button>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>


      </Box>

    </Layout>

  )
}
export default Contact
