import React from 'react'
import { Box, Card, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material'

import Images from 'app/assets/Images'
import colors from 'app/styles/colors'

// *import components
import Layout from 'app/Layout/Layout'

function Services() {

  return (
    <Layout>

      <Box sx={{
        position: "relative",
        textAlign: "center",
        height: "40vh",
        backgroundImage: `linear-gradient(0deg, rgba(29,28,85,0.7) 50%, rgba(0,0,0,0) 83%),url(${Images.servicesBanner})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionY: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "80%", }}>
          <Typography variant="h2" color="white" fontWeight="bold">
            Our Services
          </Typography>
        </Box>
      </Box>

      <Container>
        <Grid container my={5}>
          <Grid item xs={4}>
            <Card sx={{
              height: "100%",
              maxWidth: "350px",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              overflow: "hidden",
              position: "relative",
              transition: "all .3s ease-out",
              ":hover": {
                transform: "scale(1.05)",
                ".MuiBox-root": {
                  display: "block",
                  cursor: "pointer"
                }
              }
            }}>
              <Box
                onClick={() => console.log("boxClicked")}
                sx={{
                  position: "absolute",
                  top: 0, left: 0,
                  display: "none",
                  background: `linear-gradient(0deg, rgba(29,28,85,0.5) 9%, rgba(0,0,0,0) 83%)`, width: "100%", height: "320px"
                }}>
              </Box>
              <CardMedia
                component="img"
                height="140"
                image={Images.itService}
                alt=""
              />
              <CardContent>
                <Typography variant="h5" fontWeight={"bold"} textAlign="center" gutterBottom>
                  IT Services
                </Typography>
                <Typography sx={{ fontSize: "12px", color: colors.gray, my: 2 }} >
                  It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. .
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card sx={{
              height: "100%",
              maxWidth: "350px",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              overflow: "hidden",
              position: "relative",
              transition: "all .3s ease-out",
              ":hover": {
                transform: "scale(1.05)",
                ".MuiBox-root": {
                  display: "block",
                  cursor: "pointer"
                }
              }
            }}>
              <Box
                onClick={() => console.log("boxClicked")}
                sx={{
                  position: "absolute",
                  top: 0, left: 0,
                  display: "none",
                  background: `linear-gradient(0deg, rgba(29,28,85,0.5) 9%, rgba(0,0,0,0) 83%)`, width: "100%", height: "320px"
                }}>
              </Box>
              <CardMedia
                component="img"
                height="140"
                image={Images.itCreate}
                alt=""
              />
              <CardContent>
                <Typography variant="h5" fontWeight={"bold"} textAlign="center" gutterBottom>
                  ID Creation
                </Typography>
                <Typography sx={{ fontSize: "12px", color: colors.gray, my: 2 }} >
                  It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. .
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card sx={{
              height: "100%",
              maxWidth: "350px",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              overflow: "hidden",
              position: "relative",
              transition: "all .3s ease-out",
              ":hover": {
                transform: "scale(1.05)",
                ".MuiBox-root": {
                  display: "block",
                  cursor: "pointer"
                }
              }
            }}>
              <Box
                onClick={() => console.log("boxClicked")}
                sx={{
                  position: "absolute",
                  top: 0, left: 0,
                  display: "none",
                  background: `linear-gradient(0deg, rgba(29,28,85,0.5) 9%, rgba(0,0,0,0) 83%)`, width: "100%", height: "320px"
                }}>
              </Box>
              <CardMedia
                component="img"
                height="140"
                image={Images.llcFormation}
                alt=""
              />
              <CardContent>
                <Typography variant="h5" fontWeight={"bold"} textAlign="center" gutterBottom>
                  LLC Formation
                </Typography>
                <Typography sx={{ fontSize: "12px", color: colors.gray, my: 2 }} >
                  It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. .
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

      </Container>

    </Layout>
  )
}

export default Services