import { Navigate } from 'react-router-dom';

// *Import Components
import Home from 'app/view/Home/Home';
import Profile from 'app/view/User/Profile/Profile';
import Orders from 'app/view/User/Orders/Orders';
import Invoice from 'app/view/User/Invoice/Invoice';
import Services from 'app/view/Services/Services';
import About from 'app/view/About/About';
import Contact from 'app/view/Contact/Contact';
import Learning from 'app/view/Learning/Learning';
import Start from 'app/view/Start/Start';

export const AppRoutes = [
    {
        path: "/",
        component: <Home />
    },
    {
        path: "/start/type",
        component: <Start />
    },
    {
        path: "/start/company-state",
        component: <Start />
    },
    {
        path: "/start/company-info/name",
        component: <Start />
    },
    {
        path: "/start/company-info/industry",
        component: <Start />
    },
    {
        path: "/start/company-info/no-of-owners",
        component: <Start />
    },
    {
        path: "/services",
        component: <Services />
    },
    {
        path: "/learning",
        component: <Learning />
    },
    {
        path: "*",
        component: <Navigate to="/404" />
    },
    {
        path: "/about-us",
        component: <About />
    },
    {
        path: "/contact-us",
        component: <Contact />
    },
]

export const PrivateRoutes = [
    {
        path: "/profile",
        component: <Profile />
    },
    {
        path: "/orders",
        component: <Orders />
    },
    {
        path: "/invoice",
        component: <Invoice />
    },
]
