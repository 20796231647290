import clsx from 'clsx'
import React, { Fragment, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

//* Imported Components
import Images from 'app/assets/Images'
import CircularStatic from 'app/components/ProgressBar/ProgressBar'
import colors from 'app/styles/colors'
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
  activeType: {
    border: `2.5px solid ${colors.primaryLight} !important`,
    backgroundColor: `${colors.primaryShade} !important`,
  }
})

function Type() {

  //* For Selecting Type
  const [selectedTypeIndex, setSelectedTypeIndex] = useState();

  const classes = useStyles();
  return (
    <Fragment>
      <Box
        sx={{
          height: "100vh", width: '100%', backgroundImage: `linear-gradient(to right bottom, #1d1c55,#40508c, #232e57,#778ad1);`,
          display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center",
          p: 6, boxSizing: 'border-box',
        }}
      >
        <Box sx={{
          display: "flex", alignItems: "center", justifyContent: "center", mb: "25px",
          cursor: "pointer", textDecoration: "none"
        }}
          component={Link} to="/"
        >
          <img src={Images.logo2} alt="" width={60} />
          <Typography variant="h5" color={colors.white} fontWeight="bold">EasyFiles</Typography>
        </Box>
        <Box sx={{
          height: "480px", width: "55%", backgroundColor: "rgba(20,20,20,.32)", alignItems: "center",
          color: colors.white, p: 6, borderRadius: "12px",
        }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography >Company Type</Typography>
            <CircularStatic value={20} />
          </Box>
          <Box sx={{ textAlign: "center", my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>Let’s start your business.</Typography>
            <Typography variant="body2">Choose your company structure.</Typography>
          </Box>
          <Box sx={{ minHeight: "250px" }}>
            <Box sx={{
              borderRadius: "8px", backgroundColor: "rgba(20,20,20,.32)",
              cursor: "pointer", p: 2, boxSizing: 'border-box', my: 2, border: `2.5px solid transparent`, ":hover": {
                border: `2.5px solid ${colors.white
                  }`,
              }
            }}
              onClick={() => { setSelectedTypeIndex(1) }}
              className={clsx(selectedTypeIndex === 1 && `${classes.activeType}`)}>
              <Typography>LLC</Typography>
              <Typography>Great for small businesses and flexibility.</Typography>
            </Box>
            <Box sx={{
              borderRadius: "8px", backgroundColor: "rgba(20,20,20,.32)",
              cursor: "pointer", p: 2, boxSizing: 'border-box', my: 2, border: `2.5px solid transparent`,
              ":hover": {
                border: `2.5px solid ${colors.white
                  }`,
              }
            }}
              onClick={() => { setSelectedTypeIndex(2) }}
              className={clsx(selectedTypeIndex === 2 && `${classes.activeType}`)}>
              <Typography>C-Corporation</Typography>
              <Typography>Great for startups fundraising from investors.</Typography>
            </Box>
            <Box sx={{
              borderRadius: "8px", backgroundColor: "rgba(20,20,20,.32)",
              cursor: "pointer", p: 2, boxSizing: 'border-box', my: 2, border: `2.5px solid transparent`,
              ":hover": {
                border: `2.5px solid ${colors.white}`,
              }
            }}
              onClick={() => { setSelectedTypeIndex(3) }}
              className={clsx(selectedTypeIndex === 3 && `${classes.activeType}`)}>
              <Typography>I don’t know</Typography>
              <Typography>Help me to choose</Typography>
            </Box>
          </Box>
          <Box sx={{ my: 4 }}>
            <Button variant="outlined" sx={{ float: "left", color: colors.white, border: `1px solid ${colors.white}` }}
              component={Link} to="/"
            >Back</Button>
            <Button variant="contained" sx={{ float: "right" }}
              component={Link} to="/start/company-state"
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}

export default Type