import React from 'react'
import { Container, Divider, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import { AiOutlineEye, AiOutlineLink } from "react-icons/ai"

import colors from 'app/styles/colors'
import sideNavItems from 'app/Navigate'

// *import components
import Layout from 'app/Layout/Layout'
import SideNav from 'app/components/SideNav/SideNav'


function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

function Orders() {
  return (
    <Layout>
      <Divider sx={{ mb: 3 }} />
      <Container sx={{ minHeight: "60vh" }}>
        <Grid container spacing={3}>
          <Grid item xs={3.5}>
            <SideNav data={sideNavItems} />
          </Grid>
          <Grid item xs={8.5}>
            <Typography variant="h5" fontWeight={"bold"} sx={{ my: 2 }} color="secondary">Orders</Typography>
            <TableContainer component={Paper}

              sx={{
                bgcolor: colors.white,
                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
                borderRadius: "10px",
              }}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead sx={{ bgcolor: colors.primaryLight }}>
                  <TableRow>
                    <TableCell sx={{ color: colors.white, fontWeight: "bold", width: "120px" }}>Order ID(#)</TableCell>
                    <TableCell sx={{ color: colors.white, fontWeight: "bold" }}>Service Name</TableCell>
                    <TableCell sx={{ color: colors.white, fontWeight: "bold" }}>Order Date</TableCell>
                    <TableCell sx={{ color: colors.white, fontWeight: "bold" }}>Status</TableCell>
                    <TableCell sx={{ color: colors.white, fontWeight: "bold" }}>Payment</TableCell>
                    <TableCell sx={{ color: colors.white, fontWeight: "bold" }}>Total ($)</TableCell>
                    <TableCell sx={{ color: colors.white, fontWeight: "bold" }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow key={row.name}>
                      <TableCell >{index + 200}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.calories}</TableCell>
                      <TableCell>{row.fat}</TableCell>
                      <TableCell>{row.carbs}</TableCell>
                      <TableCell>{row.carbs}</TableCell>
                      <TableCell>
                        <Tooltip title="Details">
                          <IconButton >
                            <AiOutlineEye size={18} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Pre-Requisite">
                          <IconButton >
                            <AiOutlineLink size={18} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default Orders