import React, { Fragment } from 'react'
import { Box, CardMedia, Typography } from '@mui/material'

//* Imported Component
import colors from 'app/styles/colors'

//* Imported Component
import Images from 'app/assets/Images'

function CardCourse() {
  return (
    <Fragment>
      <Box sx={{
        background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${Images.learningCourse})`,
        borderRadius: "12px", boxShadow: 3, width: "350px", height: '400px', my: 6,
        ":hover": {
          cursor: "pointer",
          alignItems: "center",
          ".course_card_content": {
            display: "block",
            transition: "all 0.4s ease-in",
            alignItems: "center",

          },
          ".card_content_heading": {
            display: "none",
          }
        }
      }}>
        {/* <CardMedia sx={{ width: "100%", height: "400px", borderRadius: "12px" }}
          component="img"
          image={Images.learningCourse}
          alt="Learning Course"
        /> */}
        <Box className="card_content_heading">
          <Typography variant="h6" sx={{ color: colors.primaryDark, backgroundColor: colors.white, fontWeight: "bold", }}>Digital Marketing Advance</Typography>
        </Box>
        <Box className="course_card_content"
          sx={{
            display: "none", height: '400px', backgroundColor: "rgba(255,255,255,0.8)", my: "auto",
            width: "350px", borderRadius: "12px", boxSizing: "border-box", p: 2, transition: "all 0.3s ease-in",
          }}>
          <Typography sx={{ color: colors.textSecondary }}>Boost your digital marketing skills with an advanced digital marketing course. Master Digital Marketing Strategy, Social Media Marketing, SEO, YouTube, Email, Facebook Marketing, Analytics & more. Explore the theoretical and strategic approaches to digital marketing with highly interactive projects, checklists & actionable lectures built into every section. Equip yourself with quick learning on how to market a business online from scratch across all the major marketing channels.
          </Typography>
        </Box>
      </Box>
    </Fragment>
  )
}

export default CardCourse