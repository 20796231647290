import React, { Fragment } from 'react'
import { Grid, Box, Button, Typography, FormControl, TextField, Autocomplete } from '@mui/material'
import { makeStyles } from '@mui/styles'

//* Imported Components
import Images from 'app/assets/Images'
import CircularStatic from 'app/components/ProgressBar/ProgressBar'
import colors from 'app/styles/colors'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFormLabel-root": {
      color: colors.white
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `2.5px solid ${colors.primaryLight}`,
      ":hover": {
        border: `2.5px solid ${colors.primaryLight} !important`,
      }

    },
    "& .Mui-focused": {
      color: `${colors.white} !important`

    },
    "& .MuiOutlinedInput-input": {
      color: colors.white
    },
    // "& .css-1ftyaf0": {
    //   color: colors.white
    // },
    "& .MuiSvgIcon-root": {
      color: colors.white
    },
  }
}));


//* Industry List
const industryOptions = [
  { label: 'B2B Software' },
  { label: 'B2C Software' },
  { label: 'Consultant/Contractor' },
  { label: 'E-commerce' },
  { label: 'Education' },
  { label: 'Financial Services' },
  { label: 'Healthcare' },
  { label: 'Hospitality' },
  { label: 'Legal Services' },
  { label: 'Marketing Services' },
  { label: 'Marketplace' },
  { label: 'Travel' },
  { label: 'Other' },
]


function Industry() {


  const classes = useStyles();
  return (
    <Fragment>
      <Box
        sx={{
          height: "100vh", width: '100%', backgroundImage: `linear-gradient(to right bottom, #1d1c55,#40508c, #232e57,#778ad1);`,
          display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center",
          p: 6, boxSizing: 'border-box',
        }}
      >
        <Box sx={{
          display: "flex", alignItems: "center", justifyContent: "center", mb: "25px",
          cursor: "pointer", textDecoration: "none"
        }}
          component={Link} to="/"
        >
          <img src={Images.logo2} alt="" width={60} />
          <Typography variant="h5" color={colors.white} fontWeight="bold">EasyFiles</Typography>
        </Box>
        <Box sx={{
          height: "475px", width: "55%", backgroundColor: "rgba(20,20,20,.32)", alignItems: "center",
          color: colors.white, p: 6, borderRadius: "12px",
        }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography >Company Details · Mangotech Limited Liability Company</Typography>
            <CircularStatic value={50} />
          </Box>
          <Box sx={{ textAlign: "center", my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>What industry is your company in?</Typography>
            {/* <Typography variant="body2">We'll instantly check if your company name is available with the State of Wyoming.</Typography> */}
          </Box>
          <Grid container sx={{ justifyContent: "space-between", minHeight: "304px" }}>
            <Grid item xs={12} md={12}>
              <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                <Autocomplete
                  className={classes.root}
                  disablePortal
                  id="combo-box-demo"
                  options={industryOptions}
                  renderInput={(params) => <TextField {...params}
                    variant="outlined"
                    label="Industry"
                  />}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box>
            <Typography></Typography>

          </Box>
          <Box sx={{ my: 4 }}>
            <Button variant="outlined" sx={{ float: "left", color: colors.white, border: `1px solid ${colors.white}` }}
              component={Link} to="/start/company-info/name"
            >Back</Button>
            <Button variant="contained" sx={{ float: "right" }}
              component={Link} to="/start/company-info/no-of-owners"
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}

export default Industry