import { Box, CardMedia, Container, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import colors from 'app/styles/colors';
import Layout from 'app/Layout/Layout';
import Images, { World } from 'app/assets/Images';
import { makeStyles } from '@mui/styles';
import { fontWeight } from '@mui/system';

const useStyles = makeStyles({
  boxStyle: {
    border: `1px solid ${colors.gray}`,
    borderRadius: "10px",
    padding: "20px 10px",
    minHeight: "50px",
    cursor: "pointer",
    textAlign: "center",
    "&:hover": {
      border: `1px solid ${colors.primaryDark}`
    }
  },
  img: {
    borderRadius: "20px"
  },
  listItem: {
    color: colors.gray,
    fontSize: "14px"
  },
  boxes: {
    fontWeight: "bold",
    border: `1px solid ${colors.gray}`,
    padding: "24px 0px",
    borderRadius: "10px",
    textAlign: "center",
    transition: "all .3s ease-in",
    "&:hover": {
      backgroundColor: colors.primaryLight,
      color: colors.white
    }
  }
})

function About() {

  const classes = useStyles()

  return (
    <Layout>

      <Box sx={{
        py: 3,
        bgcolor: "#eeeeee"

      }}>
        <Container>
          <Grid container alignItems="center" justifyContent="center" >

            <Grid item xs={12} md={6} >
              <Typography variant='h4' fontWeight="bold"
                sx={{
                  fontWeight: 550,
                  pb: 5,
                  color: colors.primaryDark,
                  ":hover": {
                    color: colors.primaryLight,
                  },
                }}
              >
                About Us
                <Divider fontWeight='bold' sx={{
                  background: "black",

                }} />
              </Typography>

              <Typography component="div" fontWeight="bold"
                sx={{
                  fontSize: "15px",

                  color: colors.primaryDark,
                  ":hover": {
                    color: colors.primaryLight,

                  },
                  textAlign: 'left',
                  fontWeight: 400
                }}
              >
                Considering that the chain’s main draw is high quality food at low prices, it makes sense that customers might question how Trader Joe’s can afford to charge less than other brands. This short explanation answers that question, and it’s easy to see how it could be very effective at drawing new customers to their local store.

              </Typography>

            </Grid>
            <Grid item xs={12} md={6}>
              {/* <CardMedia
                component="img"
               sx={{width:"80% !important",textAlign:"center"}}
                image={Images.aboutbg}
                alt="green iguana"
              /> */}
              <Box sx={{
                textAlign: {
                  xs: "center",
                  md: "right"
                }
              }}>
                <img src={Images.aboutbg} width="70%" alt="" />
              </Box>

            </Grid>

          </Grid>
        </Container>
      </Box>

      <Box sx={{ py: 3 }}>
        <Container>
          <Grid container alignItems="center" justifyContent="space-between" sx={{ flexWrap: { xs: "wrap-reverse" } }}>


            <Grid item xs={12} md={6}>
              {/* <CardMedia
        component="img"
        height="340"
        sx={{width:"450px" }}
        image={Images.img2}
        alt="ops"
      />  */}
              <Box sx={{
                textAlign: {
                  xs: "center",
                  md: "left"
                }
              }}>
                <img src={Images.img2} width="70%" alt="" />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} >
              <Typography
                variant='h4' fontWeight="bold"
                sx={{

                  color: colors.primaryDark,
                  ":hover": {
                    color: colors.primaryLight,
                  },
                  fontWeight: 550,
                  py: 3

                }}
              >
                How This Incredible Journey Started?
                <Divider fontWeight='bold' sx={{
                  background: "black",

                }} />
              </Typography>
              <Typography
                component="div" fontWeight="bold"
                sx={{
                  fontSize: "15px",

                  color: colors.primaryDark,
                  ":hover": {
                    color: colors.primaryLight,

                  },
                  textAlign: 'left',
                  fontWeight: 400
                }}
              >
                We started back in 2019 with just a few people on our team. We were so keenly interested in the eCommerce business that we quit our jobs to explore this area to the roots.  We were so keenly interested in the eCommerce business that we quit our jobs to explore this area to the roots.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box sx={{ bgcolor: "#eeeeee", py: 3 }}>
        <Container>
          <Grid container alignItems="center" justifyContent="space-between" >

            <Grid item xs={12} md={6}>
              <Typography
                variant='h4' fontWeight="bold"
                sx={{

                  color: colors.primaryDark,
                  ":hover": {
                    color: colors.primaryLight,

                  },
                  py: 4,
                  fontWeight: 550
                }}
              >
                Future Goals
                <Divider fontWeight='bold' sx={{
                  background: "black",

                }} />
              </Typography>
              <Typography component="div" fontWeight="bold"
                sx={{
                  fontSize: "15px",

                  color: colors.primaryDark,
                  ":hover": {
                    color: colors.primaryLight,

                  },
                  textAlign: 'left',
                  fontWeight: 400
                }}>
                Our goal is to make your experience with us even more smooth and more pleasant by working on what we might have been lacking on.Along with getting the best at what we already are offering you, Mashr is now adding more eCommerce marketplaces to its services so that we have it all covered for you.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <CardMedia
        component="img"
        height="390"
        sx={{width:"400px" }}
        image={Images.img}
        alt="ops"
      />  */}
              <Box sx={{
                textAlign: {
                  xs: "center",
                  md: "right"
                }
              }}>
                <img src={Images.img} width="70%" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box sx={{ py: 3 }}>
        <Container>
          <Grid container alignItems="center" justifyContent="space-between" sx={{ flexWrap: { xs: "wrap-reverse" } }} >

            <Grid item xs={12} md={6}>
              {/* <CardMedia
        component="img"
        height="400"
        sx={{width:"430px" }}
        image={Images.img4}
        alt="ops"
      />  */}
              <Box sx={{
                textAlign: {
                  xs: "center",
                  md: "left"
                }
              }}>
                <img src={Images.img4} width="70%" alt="" />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant='h4' fontWeight="bold"
                sx={{

                  color: colors.primaryDark,
                  ":hover": {
                    color: colors.primaryLight,

                  },
                  py: 4,

                  fontWeight: 550
                }}
              >
                Mashr is going Global
                <Divider fontWeight='bold' sx={{
                  background: "black",

                }} />
              </Typography>
              <Typography component="div" fontWeight="bold"
                sx={{
                  fontSize: "15px",

                  color: colors.primaryDark,
                  ":hover": {
                    color: colors.primaryLight,

                  },
                  textAlign: 'left',
                  fontWeight: 400
                }}>
                The years of success and immense appreciation from our customers have given us the confidence to step forward and offer our services globally to facilitate businesses around the world.

                So, gear up for an everlasting bond and support from our end to clear all your doubts and ensure that your investment leads to a profitable business because we have all the elements to make it successful.So, gear up for an everlasting bond.
              </Typography>
            </Grid>

          </Grid>
        </Container>
      </Box>

      <Box
        sx={{
          py: 3,
          bgcolor: "#eeeeee",
          // backgroundImage:" linear-gradient(to top,#bcbcbc, #cccccc, #dddddd, #eeeeee, #ffffff)",

        }}
      >

        <Container>
          <Grid container padding={3} alignItems="center" justifyContent="space-between" >


            <Grid item xs={12} md={6}>
              <Typography variant='h4' fontWeight="bold"
                sx={{

                  color: colors.primaryDark,
                  ":hover": {
                    color: colors.primaryLight,

                  },
                  py: 4,
                  textAlign: 'left',

                  fontWeight: 550,

                }}>
                Achievements
                <Divider fontWeight='bold' sx={{
                  background: "black",

                }} />
              </Typography>
              <Typography
                component="div" fontWeight="bold"
                sx={{
                  fontSize: "15px",

                  color: colors.primaryDark,
                  ":hover": {
                    color: colors.primaryLight,

                  },
                  textAlign: 'left',
                  fontWeight: 400,

                }} >
                When it comes to joining an e-Commerce business, timing with the right approach is crucial. So, we have set the stage for our customers by providing the best services with the winning strategies. We have served thousands of happy customers so far who are now successfully doing business on platforms like Amazon, eBay, Etsy, and other online marketplaces.
              </Typography>

            </Grid>
            <Grid item xs={12} md={6}>
              {/* <CardMedia
              component="img"
              height="520"
              sx={{ width: " 620px" }}
              image={Images.img3}
              alt="green iguana"
            /> */}
              <Box sx={{
                textAlign: {
                  xs: "center",
                  md: "right"
                }
              }}>
                <img src={Images.img3} width="70%" alt="" />
              </Box>

            </Grid>

          </Grid>
        </Container>

      </Box>

    </Layout>
  )
}

export default About