import React from 'react'
import { Box, Container, Divider, FormControl, Grid, TextField, Typography } from '@mui/material'

import sideNavItems from 'app/Navigate'

// *import components
import Layout from 'app/Layout/Layout'
import SideNav from 'app/components/SideNav/SideNav'


function Profile() {

  return (
    <Layout>
      <Divider sx={{ mb: 3 }} />
      <Container sx={{ minHeight: "60vh" }}>
        <Grid container spacing={3}>

          <Grid item xs={3.5}>
            <SideNav data={sideNavItems} />
          </Grid>

          <Grid item xs={8.5}>

            <Box sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", p: 2 }}>
              <Typography variant="h5" fontWeight={"bold"} sx={{ my: 2 }} color="secondary">Account Detail</Typography>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormControl fullWidth variant="standard"  >
                    <TextField
                      variant="outlined"
                      type="text"
                      label="Full Name"
                    // error={errors?.email?.message && (true)}
                    // {...register("email", {
                    //   required: 'Email is required',
                    //   pattern: {
                    //     value: emailRegex,
                    //     message: 'Please enter a valid email address',
                    //   }
                    // })}
                    />
                    {/* {errors?.email?.message && (
                  <Error message={errors?.email?.message} /> */}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth variant="standard"  >
                    <TextField
                      variant="outlined"
                      type="email"
                      label="Email"
                    // error={errors?.email?.message && (true)}
                    // {...register("email", {
                    //   required: 'Email is required',
                    //   pattern: {
                    //     value: emailRegex,
                    //     message: 'Please enter a valid email address',
                    //   }
                    // })}
                    />
                    {/* {errors?.email?.message && (
                  <Error message={errors?.email?.message} /> */}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth variant="standard"  >
                    <TextField
                      variant="outlined"
                      type="number"
                      label="Phone"
                    // error={errors?.email?.message && (true)}
                    // {...register("email", {
                    //   required: 'Email is required',
                    //   pattern: {
                    //     value: emailRegex,
                    //     message: 'Please enter a valid email address',
                    //   }
                    // })}
                    />
                    {/* {errors?.email?.message && (
                  <Error message={errors?.email?.message} /> */}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth variant="standard"  >
                    <TextField
                      variant="outlined"
                      type="password"
                      label="Password"
                    // error={errors?.email?.message && (true)}
                    // {...register("email", {
                    //   required: 'Email is required',
                    //   pattern: {
                    //     value: emailRegex,
                    //     message: 'Please enter a valid email address',
                    //   }
                    // })}
                    />
                    {/* {errors?.email?.message && (
                  <Error message={errors?.email?.message} /> */}
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", p: 2, mt: 2 }}>
              <Typography variant="h5" fontWeight={"bold"} sx={{ my: 2 }} color="secondary">Business Address</Typography>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormControl fullWidth variant="standard"  >
                    <TextField
                      variant="outlined"
                      type="text"
                      label="Business/Residential Address"
                    // error={errors?.email?.message && (true)}
                    // {...register("email", {
                    //   required: 'Email is required',
                    //   pattern: {
                    //     value: emailRegex,
                    //     message: 'Please enter a valid email address',
                    //   }
                    // })}
                    />
                    {/* {errors?.email?.message && (
                  <Error message={errors?.email?.message} /> */}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth variant="standard"  >
                    <TextField
                      variant="outlined"
                      type="text"
                      label="City"
                    // error={errors?.email?.message && (true)}
                    // {...register("email", {
                    //   required: 'Email is required',
                    //   pattern: {
                    //     value: emailRegex,
                    //     message: 'Please enter a valid email address',
                    //   }
                    // })}
                    />
                    {/* {errors?.email?.message && (
                  <Error message={errors?.email?.message} /> */}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth variant="standard"  >
                    <TextField
                      variant="outlined"
                      type="text"
                      label="State"
                    // error={errors?.email?.message && (true)}
                    // {...register("email", {
                    //   required: 'Email is required',
                    //   pattern: {
                    //     value: emailRegex,
                    //     message: 'Please enter a valid email address',
                    //   }
                    // })}
                    />
                    {/* {errors?.email?.message && (
                  <Error message={errors?.email?.message} /> */}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth variant="standard"  >
                    <TextField
                      variant="outlined"
                      type="text"
                      label="Country"
                    // error={errors?.email?.message && (true)}
                    // {...register("email", {
                    //   required: 'Email is required',
                    //   pattern: {
                    //     value: emailRegex,
                    //     message: 'Please enter a valid email address',
                    //   }
                    // })}
                    />
                    {/* {errors?.email?.message && (
                  <Error message={errors?.email?.message} /> */}
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

          </Grid>

        </Grid>
      </Container>
    </Layout>
  )
}

export default Profile