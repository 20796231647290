import React, { Fragment } from 'react'
import Type from './shared/Type'
import CompanyState from './shared/CompanyState'
import { useLocation } from 'react-router-dom'
import Name from './shared/CompanyInfo/Name'
import Industry from './shared/CompanyInfo/Industry'
import NoOfOwners from './shared/CompanyInfo/NoOfOwners'

function Start() {
  const { pathname } = useLocation()

  return (
    <Fragment>
      {pathname === '/start/type' &&
        <Type />
      }

      {pathname === '/start/company-state' &&
        <CompanyState />
      }

      {pathname === '/start/company-info/name' &&
        <Name />
      }

      {pathname === '/start/company-info/industry' &&
        <Industry />
      }

      {pathname === '/start/company-info/no-of-owners' &&
        <NoOfOwners />
      }

    </Fragment>
  )
}

export default Start