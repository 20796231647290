import React, { Fragment, useState } from 'react'
import { Avatar, Box, Button, Container, Divider, Grid, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { BsFillPersonFill } from "react-icons/bs"
import { FiLogOut } from "react-icons/fi"
import { AiOutlineMenu } from "react-icons/ai"

import colors from 'app/styles/colors'
import Images from 'app/assets/Images'
import useAuth from 'app/hooks/useAuth'
import clsx from 'clsx'
import Login from 'app/view/Auth/Login'

const useStyles = makeStyles({
  link: {
    textDecoration: "none",
    color: colors.primaryDark,
    fontWeight: 500,
    fontSize: "14px",
    '&:hover': {
      color: colors.primaryLight,
      transition: '0.3s',
    }
  },
  activeLink: {
    fontSize: "14px",
    textDecoration: "none",
    color: colors.primaryLight,
    fontWeight: 700,
    '&:hover': {
      color: colors.primaryDark,
      transition: '0.3s',
    }
  },
})


function Header() {

  const { pathname } = useLocation()

  const { user, signOut } = useAuth()

  const classes = useStyles()

  const [show, setShow] = useState(false);
  const [menutoggle, setMenutoggle] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const openDropdown = Boolean(anchorEl);


  const handleChange = () => {
    setShow(!show)
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dropDown = (
    <Menu
      anchorEl={anchorEl}
      open={openDropdown}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          width: "150px",
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '.MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
    >

      <MenuItem component={Link} to={"/profile"} sx={{ my: 1 }}>
        <ListItemIcon>
          <BsFillPersonFill />
        </ListItemIcon>
        Profile
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => signOut()}>
        <ListItemIcon>
          <FiLogOut />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );

  return (

    <Container>

      <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{ py: 3 }}>
        <Grid item xs={3}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src={Images.logo} alt="" width={50} />
            <Typography variant="body1" color="secondary" fontWeight="bold">EasyFiles</Typography>
          </Box>
        </Grid>

        <Grid item xs={6} sx={{ textAlign: "right" }}>
          <Box sx={{ display: { md: "flex", xs: "none" }, justifyContent: "space-between", alignItems: "center" }}>
            <Link to="/" className={clsx(`${classes.link}`, pathname === "/" && `${classes.activeLink}`)}>
              Home
            </Link>
            <Link to="/services" className={clsx(`${classes.link}`, pathname === "/services" && `${classes.activeLink}`)}>
              Services
            </Link>
            <Link to="/learning" className={clsx(`${classes.link}`, pathname === "/" && `${classes.activeLink}`)}>
              Learning
            </Link>
            <Link to="/about-us" className={clsx(`${classes.link}`, pathname === "/about-us" && `${classes.activeLink}`)}>
              About Us
            </Link>
            <Link to="" className={classes.link}>
              Resource Center
            </Link>
            <Link to="/contact-us" className={clsx(`${classes.link}`, pathname === "/contact-us" && `${classes.activeLink}`)}>
              Contact Us
            </Link>

            {user ? (
              <Fragment>
                <IconButton
                  onClick={handleClick}
                  size="small"
                  aria-haspopup="true"
                >
                  <Avatar sx={{ width: 32, height: 32, }} />
                </IconButton>
                {dropDown}
              </Fragment>
            ) : (
              <Button variant='contained'
                component={Link}
                to="/auth/sign-in"
                sx={{
                  bgcolor: colors.primaryShade,
                  borderRadius: "20px",
                  fontSize: "12px",
                  boxShadow: 0,
                  color: colors.primaryDark,
                  ":hover": {
                    color: colors.white,
                  }
                }}>
                Login
              </Button>
            )}

          </Box>
          <Typography component="div" sx={{
            display: {
              xs: "block",
              md: "none",
              textAlign: "right",
            },
            width: '100%',
          }}>
            <IconButton onClick={() => { setMenutoggle(!menutoggle) }}  >
              <AiOutlineMenu style={{
                fontSize: "30px",
                color: colors.primary,
                cursor: "pointer",
                textAlign: "right",

              }} />
            </IconButton>
          </Typography>

        </Grid>

      </Grid>
      {menutoggle &&
        <Box sx={{ display: { md: "none", xs: "block" }, flexDirection: "column" }}>
          <Link to="/" className={clsx(`${classes.link}`, pathname === "/" && `${classes.activeLink}`)} style={{ display: "block" }}>
            <Typography sx={{ fontWeight: 550, py: 0.2, textAlign: "center" }}>Home</Typography>
          </Link>
          <Link to="/services" className={clsx(`${classes.link}`, pathname === "/services" && `${classes.activeLink}`)} style={{ display: "block" }}>
            <Typography sx={{ fontWeight: 550, py: 0.2, textAlign: "center" }}>Services</Typography>
          </Link>
          <Link to="/learning" className={clsx(`${classes.link}`, pathname === "/learning" && `${classes.activeLink}`)} style={{ display: "block" }}>
            <Typography sx={{ fontWeight: 550, py: 0.2, textAlign: "center" }}>Learning</Typography>
          </Link>
          <Link to="/about-us" className={clsx(`${classes.link}`, pathname === "/about-us" && `${classes.activeLink}`)} style={{ display: "block" }}>
            <Typography sx={{ fontWeight: 550, py: 0.2, textAlign: "center" }}>About Us</Typography>
          </Link>
          <Link to="" className={classes.link}>
            <Typography sx={{ fontWeight: 550, py: 0.2, textAlign: "center" }}>Resource Center</Typography>
          </Link>
          <Link to="/contact-us" className={clsx(`${classes.link}`, pathname === "/contact-us" && `${classes.activeLink}`)} style={{ display: "block" }}>
            <Typography sx={{ fontWeight: 550, py: 0.2, textAlign: "center" }}>Contact Us</Typography>
          </Link>

          {user ? (
            <Fragment>
              <IconButton
                onClick={handleClick}
                size="small"
                aria-haspopup="true"
              >
                <Avatar sx={{ width: 32, height: 32, }} />
              </IconButton>
              {dropDown}
            </Fragment>
          ) : (
            <Button variant='contained'
              component={Link}
              to="/auth/sign-in"
              sx={{
                bgcolor: colors.primaryShade,
                borderRadius: "20px",
                fontSize: "12px",
                boxShadow: 0,
                color: colors.primaryDark,
                ":hover": {
                  color: colors.white,
                }
              }}>
              Login
            </Button>
          )}

        </Box>
      }
    </Container>

  )
}

export default Header