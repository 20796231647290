import React, { Fragment } from 'react'
import { Grid, Box, Button, Typography, FormControl, TextField, Autocomplete, Switch } from '@mui/material'
import { makeStyles } from '@mui/styles'

//* Imported Components
import Images from 'app/assets/Images'
import CircularStatic from 'app/components/ProgressBar/ProgressBar'
import colors from 'app/styles/colors'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFormLabel-root": {
      color: colors.white
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `2.5px solid ${colors.primaryLight}`,
      ":hover": {
        border: `2.5px solid ${colors.primaryLight} !important`,
      }

    },
    "& .Mui-focused": {
      color: `${colors.white} !important`

    },
    "& .MuiOutlinedInput-input": {
      color: colors.white
    },
    // "& .css-1ftyaf0": {
    //   color: colors.white
    // },
    "& .MuiSvgIcon-root": {
      color: colors.white
    },
  }
}));


//* Industry List
const individualOwnerOptions = [

  { label: '0' }, { label: '1' }, { label: '2' },
  { label: '3' }, { label: '4' }, { label: '5' },
  { label: '6' }, { label: '7' }, { label: '8' },
  { label: '9' }, { label: '10' }, { label: '11' },
  { label: '12' }, { label: '13' }, { label: '14' },
  { label: '15' }, { label: '16' }, { label: '17' },
  { label: '18' }, { label: '19' }, { label: '20' },
]


function NoOfOwners() {


  const classes = useStyles();
  return (
    <Fragment>
      <Box
        sx={{
          height: "100vh", width: '100%', backgroundImage: `linear-gradient(to right bottom, #1d1c55,#40508c, #232e57,#778ad1);`,
          display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center",
          p: 6, boxSizing: 'border-box',
        }}
      >
        <Box sx={{
          display: "flex", alignItems: "center", justifyContent: "center", mb: "25px",
          cursor: "pointer", textDecoration: "none"
        }}
          component={Link} to="/"
        >
          <img src={Images.logo2} alt="" width={60} />
          <Typography variant="h5" color={colors.white} fontWeight="bold">EasyFiles</Typography>
        </Box>
        <Box sx={{
          height: "475px", width: "55%", backgroundColor: "rgba(20,20,20,.32)", alignItems: "center",
          color: colors.white, p: 6, borderRadius: "12px",
        }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography >Individual Owner Details · Mangotech LLC</Typography>
            <CircularStatic value={60} />
          </Box>
          <Box sx={{ textAlign: "center", my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>How many owners and parent companies will Mangotech, LLC own?</Typography>
            {/* <Typography variant="body2">We'll instantly check if your company name is available with the State of Wyoming.</Typography> */}
          </Box>
          <Box sx={{ minHeight: "272px" }}>
            <Grid container sx={{ justifyContent: "space-between" }}>
              <Grid item xs={12} md={12}>
                <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                  <Autocomplete
                    className={classes.root}
                    disablePortal
                    id="combo-box-demo"
                    options={individualOwnerOptions}
                    renderInput={(params) => <TextField {...params}
                      variant="outlined"
                      label="Individual Owners*"
                    />}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>Part of my company will be owned by another company</Typography>
              <Switch />
            </Box>
          </Box>
          <Box sx={{ my: 4 }}>
            <Button variant="outlined" sx={{ float: "left", color: colors.white, border: `1px solid ${colors.white}` }}
              component={Link} to="/start/company-info/industry"
            >Back</Button>
            <Button variant="contained" sx={{ float: "right" }}
              component={Link} to="/start/company-info/no-of-owners"
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}


export default NoOfOwners