import React, { Fragment, useState } from 'react';
import { Box, Typography, AppBar, Toolbar, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';

import useAuth from 'app/hooks/useAuth';
import colors from 'app/styles/colors';

const useStyles = makeStyles({
  active: {
    backgroundColor: colors.primaryLight,
    boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
    transform: "translateX(3%)",
    // borderLeft: `2px solid ${colors.primaryLight}`,
    // borderRight: `2px solid ${colors.primaryLight}`,
    '&>.MuiTypography-root': {
      color: colors.white,
      fontWeight: "bold"
    },

  }
});

function SideNav({ data }) {

  const classes = useStyles()
  const { signOut, name } = useAuth()

  const navigate = useNavigate()

  const { pathname } = useLocation()



  const [show, setShow] = useState(false)
  const handleChange = () => {
    setShow(!show)
  }

  return (
    <Fragment>
      <Box sx={{
        py: 3,
        bgcolor: colors.white,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
        px: 2,
        borderRadius: "10px",
        display: {
          xs: "none",
          md: "block"
        }
      }} >
        <Box sx={{ textAlign: "center", py: 2, bgcolor: colors.primaryDark, mb: 2, borderRadius: "10px" }}>
          <Typography variant="h5" fontWeight={"bold"} color="white">Johan Truax</Typography>
          <Typography variant="body1" color="darkgray" >Personal Details</Typography>
        </Box>
        {data.map((item, index) => (
          <Box key={index}
            onClick={() => { navigate(item.path) }}
            className={pathname === item.path ? classes.active : pathname.substring(0, 7) === item.path ? classes.active : null}
            sx={{
              p: 2,
              borderRadius: "10px",
              mb: 1,
              // borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
              '.listItem': {
                fontSize: 25,
                verticalAlign: 'bottom',
                mr: 1,
                color: pathname === item.path ? `${colors.white} !important` : `${colors.black} !important`
              },
              '&:hover': {
                bgcolor: colors.primaryDark,
                transition: "all .1s ease-in",
                color: colors.white,
                cursor: 'pointer',
                '&>.MuiTypography-root': {
                  fontWeight: "bold"
                },
                '.listItem': {
                  color: `${colors.white} !important`,
                  transition: "all .1s ease-in",
                }
              },
            }}>
            {item.icon}
            <Typography variant="body2" component="span" >{item.label}</Typography>
          </Box>
        ))}
        <Box
          onClick={() => signOut()}
          sx={{
            p: 2,
            borderRadius: "10px",
            mb: 1,
            '&:hover': {
              transition: "all .1s ease-in",
              bgcolor: colors.primaryDark,
              color: colors.white,
              fontWeight: "bold",
              cursor: 'pointer',
              '&>.MuiTypography-root': {
                fontWeight: "bold"
              },
            },
          }}>
          <FiLogOut className='listItem' style={{ fontSize: 25, verticalAlign: 'bottom', marginRight: "8px" }} />
          <Typography variant="body2" component="span" >Sign Out</Typography>
        </Box>
      </Box>

      {/* responsive */}
      <Box sx={{
        flexGrow: 1, display: {
          md: "none"
        }
      }}>
        <AppBar position="static" sx={{ bgcolor: colors.white, mt: 3, }}>
          <Toolbar variant="dense">
            <IconButton onClick={() => { handleChange() }} color="inherit" edge="start" sx={{ mr: 2 }}>
              {/* <Menu sx={{ color: colors.primary }} /> */}
            </IconButton>
          </Toolbar>
          {show &&
            <Box>
              <Box sx={{ textAlign: "center", py: 2 }}>
                {/* <img src={Images.happyFace} width="60px" alt="" /> */}
                <Typography sx={{ fontWeight: "bold", fontSize: "22px", color: colors.black }}>{name}</Typography>
              </Box>
              {data.map((item, index) => (
                <Box key={index}
                  onClick={() => { navigate(item.path) }}
                  className={pathname === item.path ? classes.active : null}
                  sx={{
                    p: 2,
                    borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
                    '&:hover': {
                      bgcolor: colors.primary,
                      cursor: 'pointer',
                      '.MuiSvgIcon-root': {
                        color: colors.white,
                      },
                      '.MuiTypography-root': {
                        color: colors.white,
                      },
                    },
                  }}>
                  {item.icon}
                  <Typography variant="body2" component="span" color={colors.black} >{item.label}</Typography>
                </Box>
              ))}
              <Box
                sx={{
                  p: 2,
                  bgcolor: colors.white,
                  color: colors.black,
                  '&:hover': {
                    bgcolor: colors.primary,
                    color: colors.white,
                    cursor: 'pointer',
                    '.MuiSvgIcon-root': {
                      cursor: 'pointer',
                      color: colors.white,
                    },
                  },
                }}>
                {/* <Logout color='primary' sx={{ fontSize: 25, verticalAlign: 'bottom', mr: 1 }} /> */}
                <Typography variant="body2" component="span" >Sign Out</Typography>
              </Box>
            </Box>
          }
        </AppBar>
      </Box>

    </Fragment>
  );
}

export default SideNav;