import clsx from 'clsx'
import React, { Fragment, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

//* Imported Components
import Images from 'app/assets/Images'
import CircularStatic from 'app/components/ProgressBar/ProgressBar'
import colors from 'app/styles/colors'
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
  activeType: {
    border: `2.5px solid ${colors.primaryLight} !important`,
    backgroundColor: `${colors.primaryShade} !important`,
  }
})

function CompanyState() {

  //* For Selecting State
  const [selectedStateIndex, setSelectedStateIndex] = useState();

  const classes = useStyles();
  return (
    <Fragment>
      <Box
        sx={{
          height: "100vh", width: '100%', backgroundImage: `linear-gradient(to right bottom, #1d1c55,#40508c, #232e57,#778ad1);`,
          display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center",
          p: 6, boxSizing: 'border-box',
        }}
      >
        <Box sx={{
          display: "flex", alignItems: "center", justifyContent: "center", mb: "25px",
          cursor: "pointer", textDecoration: "none"
        }}
          component={Link} to="/"
        >
          <img src={Images.logo2} alt="" width={60} />
          <Typography variant="h5" color={colors.white} fontWeight="bold">EasyFiles</Typography>
        </Box>
        <Box sx={{
          height: "475px", width: "55%", backgroundColor: "rgba(20,20,20,.32)", alignItems: "center",
          color: colors.white, p: 6, borderRadius: "12px",
        }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography >State</Typography>
            <CircularStatic value={30} />
          </Box>
          <Box sx={{ minHeight: "360px" }}>
            <Box sx={{ textAlign: "center", my: 3 }}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>Choose your registration state.</Typography>
              {/* <Typography variant="body2">Choose your company structure.</Typography> */}
            </Box>
            <Box sx={{
              borderRadius: "8px", backgroundColor: "rgba(20,20,20,.32)",
              cursor: "pointer", p: 2, boxSizing: 'border-box', my: 2, border: `2.5px solid transparent`,
              ":hover": {
                border: `2.5px solid ${colors.white}`,
              }
            }}
              onClick={() => { setSelectedStateIndex(1) }}
              className={clsx(selectedStateIndex === 1 && `${classes.activeType}`)}>
              <Typography>Delaware</Typography>
              <Typography>Great for startups and corporations due to pro-business laws and strong legal protections.</Typography>
            </Box>
            <Box sx={{
              borderRadius: "8px", backgroundColor: "rgba(20,20,20,.32)",
              cursor: "pointer", p: 2, boxSizing: 'border-box', my: 2, border: `2.5px solid transparent`,
              ":hover": {
                border: `2.5px solid ${colors.white
                  }`,
              }
            }}
              onClick={() => { setSelectedStateIndex(2) }}
              className={clsx(selectedStateIndex === 2 && `${classes.activeType}`)}>
              <Typography>Wyoming</Typography>
              <Typography>Great for LLCs and smaller companies due to easier formation requirements, lower fees, and simplified tax structure.</Typography>
            </Box>
            <Typography sx={{
              color: colors.white, cursor: "pointer",
              ":hover": {
                textDecoration: "underline"
              }
            }}> What’s the difference? </Typography>
          </Box>
          <Box sx={{ my: 4 }}>
            <Button variant="outlined" sx={{ float: "left", color: colors.white, border: `1px solid ${colors.white}` }}
              component={Link} to="/start/type"
            >Back</Button>
            <Button variant="contained" sx={{ float: "right" }}
              component={Link} to="/start/company-info/name"
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}


export default CompanyState