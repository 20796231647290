import React, { Fragment } from 'react'

import Footer from './Footer'
import Header from './Header'

function Layout({ children }) {
  return (
    <Fragment>
      {/* ====== Header ====== */}
      <Header />

      {/* ====== Body ====== */}
      {children}

      {/* ====== Footer ====== */}
      <Footer />
    </Fragment>
  )
}

export default Layout