const colors = {
  primaryLight: "#40508c",
  primaryShade: "rgba(64,80,140,0.3)",
  primaryDark: "#1d1c55",
  black: "#000",
  white: "#fff",
  gray: "#a6a6a6",
  lightBlack: "#333333",
  textSecondary: "rgb(99, 114, 129)",
  error: "#b71c1c",
}

export default colors